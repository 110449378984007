

@font-face {
    font-family: 'Univers';
    src: url('./assets/fonts/subset-UniversLTStd.woff2') format('woff2'),
        url('./assets/fonts/subset-UniversLTStd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers';
    src: url('./assets/fonts/subset-UniversLTStd-Black.woff2') format('woff2'),
        url('./assets/fonts/subset-UniversLTStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers';
    src: url('./assets/fonts/subset-UniversLTStd-Bold.woff2') format('woff2'),
        url('./assets/fonts/subset-UniversLTStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UniversCn';
    src: url('./assets/fonts/subset-UniversLTStd-BoldCn.woff2') format('woff2'),
        url('./assets/fonts/subset-UniversLTStd-BoldCn.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



$blue1: #333366;
$blue2: #008FD1;
$blue3: #00B1EB; 
$yellow: #D1A341;
$green: #66CC00;
$gradient-yellow: radial-gradient(50% 50% at 50% 50%, #FFDE98 0%, $yellow 100%);

$primary:                     $yellow;
$secondary:                   $blue1;
$font-family-sans-serif:      "Univers";
$font-size-base:              1rem;
$border-radius:               10px;
$input-btn-padding-y:         .5rem;
$input-btn-padding-x:         .75rem;
$line-height-base:            1.4;

$btn-font-size:               1.2rem;
$btn-font-size-lg:            1.2rem;
$btn-font-size-sm:            1.2rem;
$btn-font-weight:             700;
$input-btn-font-family:       $font-family-sans-serif;

$form-feedback-margin-top:          0rem;
$form-feedback-font-size:           0.8rem;
$form-feedback-valid-color:         $green;
$form-feedback-invalid-color:         #FF4500;
$form-feedback-margin-top:          0.2rem;
//$form-feedback-icon-valid-color:    $form-feedback-valid-color;
//$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$headings-font-weight:        700;


@import "~bootstrap/scss/bootstrap";

.debug {
  position:fixed;
  left:0;
  top:0;
  z-index:2;
  color:#000;
}


.app {
  width: 800px;
  height: 600px;
  padding-top: 40px;
  background-image: url(./assets/img/vasica.png),url(./assets/img/BG.jpg);
  background-position: right bottom, left top;
  background-repeat: no-repeat, no-repeat;
  background-size: auto,cover;

  @media only screen and (max-width: 600px) {
    width:100%;
    height:100%;
    padding-top:15px;
    padding-bottom:150px;
    background-size: 130%, cover;
  }

  .icon-badge {
    position:absolute;
    left:20px;
    top:20px;
    @media only screen and (max-width: 600px) {
      top: 0;
      left: 0;
      width:70px;
    }
  }

  .icon-prizes {
    position:absolute;
    right:0;
    top:70px;
    opacity:1;
    transition: opacity 0.5s ease-in-out;

    &__disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .icon-close {
    position:absolute;
    right:0;
    top:0;
    cursor: pointer;
    padding:10px;
  }

  .link-register {
    cursor: pointer;
  }

  .container {

      width: 100%;
      max-width:100%;


  }

  .intro {
    padding: 0 100px;
    @media only screen and (max-width: 600px) {
      padding: 0 50px;
    }
  }

  .bun-container  {
    margin-left: -6px;
  }

  .spinner {
    position:relative;
    width:300px;
    height:300px;
    //transform: translateX(-4px) !important;
    margin: 30px 0 0 30px;


    @media only screen and (max-width: 600px) {
      width: 260px;
      margin: 0 auto;
      margin-top:0;
      margin-bottom:30px;
    }



    .star {
      position:absolute;
      left:50%;
      top:50%;
      transform: translate(-44%,-45%);
      //animation: shineStar 6s linear infinite alternate;

      @media only screen and (max-width: 600px) {
        transform: translate(-47%,-50%);
        svg {
          @media only screen and (max-width: 600px) {
            width: 200px;
          }
        }
      }

      svg {
        transform-origin: 51% 53%;
        //animation: shineStar 0.05s linear infinite alternate;

      }

      svg.active {
       // filter: drop-shadow( 0px 0px 10px rgba($yellow, 1)) drop-shadow( 0px 0px 15px rgba($yellow, 1));
      }

      &.spinning-star {
        svg {
          //animation: infinite-spinning 0.7s linear infinite;

        }
      }


    }

/*
    @keyframes shineStar {
        0%   {
            filter: drop-shadow( 0px 0px 15px rgba($yellow, 1)) drop-shadow( 0px 0px 25px rgba($yellow, 1)) ;
        }

        100% {
            filter: drop-shadow( 0px 0px 15px rgba($yellow, 0.5)) drop-shadow( 0px 0px 25px rgba($yellow, 0.5)) ;

        }
    }
*/

    @keyframes infinite-spinning {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }




    .bun {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80px;
      height: 80px;

      margin: calc(-50px / 2);
      @media only screen and (max-width: 600px) {
        width:60px;
        height:60px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      &.active img {
        animation: neon 1s ease-in-out infinite alternate;
      }

      &.selected {

      }
    }

    .active .bun.active img {
      filter: contrast(1);
      -webkit-filter: contrast(1);
    }


    .active .bun:not(.active) img {
      filter: contrast(0.5);
      -webkit-filter: contrast(0.5);
      transition: filter .25s ease-in-out;
    }
  }


}

@keyframes neon {
  from {
      filter: drop-shadow( 0px 0px 10px rgba(255,255,255, 1)) drop-shadow( 0px 0px 15px rgba(255,255,255, 1)); 
      -webkit-filter: drop-shadow( 0px 0px 10px rgba(255,255,255, 1)) drop-shadow( 0px 0px 15px rgba(255,255,255, 1));
  }
  to {
      filter: drop-shadow( 0px 0px 10px rgba(255,255,255, 0.5));
      -webkit-filter: drop-shadow( 0px 0px 10px rgba(255,255,255, 0.5));
  }
}



a,.btn,svg {
  transition: 0.2s;
}

.text-yellow-gradient {
  background: $gradient-yellow;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.btn-gradient-yellow{
  position:relative;
  -webkit-backface-visibility: hidden;
  background-image: radial-gradient(50% 50% at 50% 50%, #FFDE98 0%, $yellow 100%);
  color: $blue1;
  font-size:1.1rem;
  font-weight:700;
  box-shadow: 0px 0px 10px rgba(0, 27, 74, 0.5);
  border-radius: 10px;
  z-index: 1;


  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.3em;
    background-image: radial-gradient(70% 70% at 50% 50%, #FFDE98 0%, $yellow 100%);
    transition: opacity 0.15s ease-out;
    z-index: 2;
    opacity: 0;
  }

  &:hover:after {
    opacity: 1;
  }

  span {
    position: relative;
    z-index: 3;
    color: $blue1;
  }
}


h1,h2,h3,h4,p {
  text-shadow: 0px 0px 10px rgba(0, 27, 74, 0.5);
}

h1,h2,h3,h4 {
  font-family: $font-family-sans-serif;
}

input {
  box-shadow: 0px 0px 10px rgba(0, 27, 74, 0.5);
}

.invalid-feedback {
  margin-top: 5px;
}

.fs-8 {
  font-size: 0.85rem;
}

.fw-black {
  font-weight:900;
}

.form-check-input[type="checkbox"] {
    border-radius: 50% !important;
}



hr {
    color: inherit;
    background: $gradient-yellow !important;
    opacity: 1 !important;
    height:3px !important;
    box-shadow: 0px 0px 10px rgba(0, 27, 74, 0.5);
}


.prize-status {
  background: rgba(255,255,255, 0.6);
  box-shadow: 0px 0px 10px rgba(0, 27, 74, 0.5);
  border-radius: 10px;
  padding: 20px;

  h5 {
    color: $blue1;
  }
}

.prize-desc {
  h2 {
    font-size: 1.05rem;
  }
}
